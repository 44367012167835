import { accordion } from './components/accordion';
import { swiperDesktop, swiperMobile } from './components/swiper';
import { hamburger, mobileHeader, stickyHeader } from './components/header';
import { viewAnimation, smoothScroll } from "./util/animation";
import { outputMonth, countDown } from './util/dates';
import { inlineVideo } from './util/video';
import { phoneConcat, readMore } from './util/text';
import { cf7Redirect } from './util/window';
import collapsible from './components/collapsible';
import modal from './components/modal';
import { multistep } from './components/multistepform';

document.addEventListener('DOMContentLoaded', function () {
    collapsible();
    modal();
    mobileHeader();
    stickyHeader();
    hamburger();
    outputMonth();
    countDown();
    viewAnimation();
    accordion();
    swiperDesktop();
    swiperMobile();
    smoothScroll();
    inlineVideo();
    phoneConcat();
    multistep();
});
