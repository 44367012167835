
export function phoneConcat() {
    setTimeout(function () {
        $('.phone-text em').each(function () {
            var unsliced = $(this).text();
            var sliced = unsliced.slice(0, -2) + "...";
            $(this).text(sliced);
            var linked = "tel:" + unsliced.replace(/\s/g, '');
            $(this).click(function () {
                if ($(window).width() < 1000) {
                    window.location.href = linked;
                } else {
                    $(this).text(unsliced);
                }
            });
        });
    
    }, 3000);
}

export function readMore() {
       var rmActive = false;
        $('[data-readmore]').click(function(e){
            e.preventDefault();
            var thisRM = $(this).data('readmore');
            $('#'+thisRM).slideToggle();

            if(rmActive){
                rmActive = false;
                $('.readmore-initial').text('Read More');
            }else{
                rmActive = true;
                $('.readmore-initial').text('Read Less');
            }
        });
}