import Swiper, { Pagination, Navigation, Autoplay, Virtual } from "swiper"

// Modifies the options to include those set within the data-* attributes.
function getSwiperOptionAttributes( options, swiperElm ) {
	// These options can be set via the attributes of the swiper element
	const setable = {
		loop: 'loop',
		spaceBetween: 'spacebetween',
		slidesPerView: 'slidesperview',
		autoplay: 'autoplay',
	}

	var optionsAttributes = swiperElm.dataset

	for ( var key in setable ) {
		var value = setable[ key ]
		if ( optionsAttributes[ value ] ) {
			options[ key ] = isNaN( optionsAttributes[ value ] )
				? optionsAttributes[ key ]
				: parseInt( optionsAttributes[ value ] )
		}

		// Breakpoint attributes if any
		if ( optionsAttributes[ '768_' + value ] ) {
			options.breakpoints[ 768 ][ key ] = isNaN(
				optionsAttributes[ '768_' + value ]
			)
				? optionsAttributes[ '768_' + value ]
				: parseInt( optionsAttributes[ '768_' + value ] )
		}
		if ( optionsAttributes[ '996_' + value ] ) {
			options.breakpoints[ 996 ][ key ] = isNaN(
				optionsAttributes[ '996_' + value ]
			)
				? optionsAttributes[ '996_' + value ]
				: parseInt( optionsAttributes[ '996_' + value ] )
		}
		if ( optionsAttributes[ '1340_' + value ] ) {
			options.breakpoints[ 1340 ][ key ] = isNaN(
				optionsAttributes[ '1340_' + value ]
			)
				? optionsAttributes[ '1340_' + value ]
				: parseInt( optionsAttributes[ '1340_' + value ] )
		} else if ( optionsAttributes[ '996_' + value ] ) {
			options.breakpoints[ 1340 ][ key ] = isNaN(
				optionsAttributes[ '996_' + value ]
			)
				? optionsAttributes[ '996_' + value ]
				: parseInt( optionsAttributes[ '996_' + value ] )
		}
	}

	return options
}

export function swiperDesktop() {
	// get all sliders, we need to loop them due to different settings + nav
	var swipers = document.querySelectorAll(
		'.swiper-container:not(.control):not(.mobile)'
	)
	swipers.forEach( async function ( el, index ) {
		var closestSection = el.closest( 'section' )
		var controls = closestSection.querySelector( '.control' )
		var paginationEl = closestSection.querySelector( '.swiper-pagination' )

		// Slider default settings
		var options = {
			speed: 600,
			modules: [ Pagination, Navigation, Autoplay, Virtual ],
			loop: true,
			slidesPerView: 1,
			centeredSlides: true,
			watchSlidesProgress: true,
			autoplay: {
				delay: 3000,
				disableOnInteraction: true,
			},
			breakpoints: {
				768: {},
				996: {},
				1340: {},
			},
			navigation: {},
			pagination: {
				el: ( paginationEl ? paginationEl : '' ),
				clickable: true,
			},
			thumbs: {},
		}

		if ( el.classList.contains( 'property-slider' ) ) {
			options.slidesPerView = 2;
			options.spaceBetween = 10;
			options.centeredSlides = true;
			options.breakpoints = {
				640: {
					slidesPerView: 2
				},
				992: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},

				1400: {
					slidesPerView: 3,
					slidesOffsetBefore: 0,
				}
			};
		}

		if ( el.classList.contains( 'testimonial-slider' ) ) {
			options.slidesPerView = 1;
			options.spaceBetween = 20;
			options.centeredSlides = true;
			options.grabCursor = true,
				options.slidesOffsetBefore = -20,
				options.breakpoints = {
					640: {
						slidesPerView: 1,
					},
					992: {
						slidesPerView: 2,
					},
					1200: {
						slidesPerView: 2,
					},

					1400: {
						slidesPerView: 2,

					}
				};
		}

		// Slider attribute overrides
		options = getSwiperOptionAttributes( options, el )

		// init nav
		options.navigation = {
			nextEl: closestSection.querySelector( '.swiper-next' ),
			prevEl: closestSection.querySelector( '.swiper-prev' ),
		}

		// maybe add gallery controls to the slider
		if ( controls ) {
			var controlOptions = {
				speed: 600,
				loop: true,
				slidesPerView: 4,
			}

			controlOptions = getSwiperOptionAttributes( controlOptions, controls )

			options.thumbs.swiper = new Swiper( controls, controlOptions )
		}

		// init slider
		new Swiper( el, options )
	} )
}

export function swiperMobile() {
	// mobile sliders, like logo rows etc
	// need to loop in order to get the slide count
	var mobileSwipers = document.querySelectorAll( '.swiper-container.mobile' )
	mobileSwipers.forEach( function ( el, index ) {
		var closestSection = el.closest( 'section' )
		var slideCount = el.querySelectorAll( '.swiper-slide' ).length

		var options = {
			speed: 600,
			slidesPerView: 2,
			watchOverflow: true,
			loop: true,
			simulateTouch: false,
			observer: true,
			observeParents: true,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			breakpoints: {
				576: {
					slidesPerView: 3,
				},
				768: {
					slidesPerView: 4,
				},
				992: {
					slidesPerView: slideCount,
				},
			},
		}

		let mainSwiper = new Swiper( el, options );

		// stop video on slide change
		mainSwiper.on( 'slideChange', function () {
			let activeVideo = closestSection.querySelector( 'iframe:not([src="about:blank"])' );
			if ( activeVideo ) {
				activeVideo.src = 'about:blank';
				activeVideo.closest( '.video-preview-container' ).classList.remove( 'playing' );
			}

			// Stop wistia video when slide changes
			setTimeout( function () {
				mainSwiper.slides.forEach( function ( el, index ) {
					if ( el.classList.contains( 'swiper-slide-prev' ) || el.classList.contains( 'swiper-slide-next' ) || el.classList.contains( 'swiper-slide-active' ) ) {
						var wistiaVideo = el.querySelector( '.wistia_embed' );
						if ( wistiaVideo && wistiaVideo.wistiaApi.secondsWatched() >= 1 ) {
							wistiaVideo.wistiaApi.pause();
						}
					}
				} );
			}, 500 )

		} );

		// init nav
		options.navigation = {
			nextEl: closestSection.querySelector( '.swiper-next' ),
			prevEl: closestSection.querySelector( '.swiper-prev' ),
		}

		options = getSwiperOptionAttributes( options, el )

		// init slider
		swiper = new Swiper( el, options )
	} )
}