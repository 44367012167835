// import { Modal } from "bootstrap";

// export default function modal() {
// 	let modals = document.querySelectorAll('.modal')

// 	if (modals.length) {
// 		for (var i = 0; i < modals.length; i++) {
// 			new Modal(modals[i])
// 		}
// 	}
// }
const microModalSettings = {
	openTrigger: 'data-mm-target',
	closeTrigger: 'data-mm-dismiss',
	openClass: 'is-open',
	awaitOpenAnimation: true,
	// awaitCloseAnimation: true,
	onShow: () => {
		document.body.classList.add( 'modal-open' );
	},
	onClose: () => {
		document.body.classList.remove( 'modal-open' );
	},
};
// Popups
export default function modal() {
	MicroModal.init( microModalSettings )
	$( document ).on( 'click', '.js-popup', function () {
		console.log( 'clicked' );
		let popup = $( this ).attr( 'href' )
		console.log( popup );
		MicroModal.show( popup.slice( 1 ), microModalSettings )
	} );
}