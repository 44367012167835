export function mobileHeader() {
    var headerItems = document.querySelectorAll('.menu-item-has-children') 

    if( !headerItems.length ) return;
    
    for(var i = 0; i < headerItems.length; i++) {
        headerItems[i].addEventListener('click', function(e) { 
            if(e.target.nodeName != 'A'){
                this.classList.toggle('active');
            }
         });
    }
}

export function hamburger() {
    $('.site-header__hamburger').on('click', function(){
        $('body').toggleClass('menu-open');
    });
}

export function stickyHeader() {
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('.site-header').addClass('scrolled');
        } else {
            $('.site-header').removeClass('scrolled');
        }
    });
}