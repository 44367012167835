export function outputMonth() {
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var now = new Date();
    var thisMonth = months[now.getMonth()]; // getMonth method returns the month of the date (0-January :: 11-December)
    var output = document.getElementsByClassName('output');

    if (output.length) {

        for (var i = 0; i < output.length; i++) {
            output[i].innerHTML = thisMonth;
        }

    }
}

export function countDown() {

    if ($('.countdown-timer').length > 0) {
        var date = new Date();
        var startMinutes = date.getMinutes();
        var startSeconds = date.getSeconds();
        var remainingSeconds = 59 - startSeconds;
        var remainingMinutes = 0;

        if (startMinutes < 15) { remainingMinutes = 15 - startMinutes - 1; }
        else if (startMinutes < 30) { remainingMinutes = 30 - startMinutes - 1; }
        else if (startMinutes < 45) { remainingMinutes = (45 - startMinutes - 1); }
        else { remainingMinutes = (60 - startMinutes - 1); }


        var count = (remainingMinutes * 60 + remainingSeconds);
        var counter = setInterval(timer, 1000); //1000 will run it every 1 second

        function progress(timeleft, timetotal) {
            var progressBarWidth = 100 - ((timeleft / timetotal) * 100);
            $('.progressbar .completed').animate({ width: progressBarWidth + '%' }, 500);
        }
        function timer() {
            count = count - 1;
            if (count == -1) {
                clearInterval(counter);
                return;
            }

            var seconds = count % 60;
            var minutes = Math.floor(count / 60);
            var hours = Math.floor(minutes / 60);
            minutes %= 60;
            hours %= 60;

            var minuteString = ' minutes and ';
            if (minutes == 1) { minuteString = ' minute and ' }
            var secondString = ' seconds';
            if (seconds == 1) { secondString = ' second' }

            progress((minutes * 60 + seconds), 900);
            document.querySelectorAll(".countdown-timer .time-left").forEach(function (selected, index) {
                if (seconds < 1 && minutes < 1) {
                    selected.innerHTML = $(selected).closest('.countdown-timer').data('end-text');
                }
                if (minutes < 1) {
                    selected.innerHTML = seconds + secondString; // watch for spelling
                } else {
                    selected.innerHTML = minutes + minuteString + seconds + secondString; // watch for spelling
                }
            });

        }
    }

}